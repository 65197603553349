function renderValidationHtml(errors) {

    var html = '<div class="alert alert-danger" role="alert">';
    html = html + '<strong>Graag onderstaande zaken eerst corrigeren:</strong>'
    html = html + '<ul>';
    $.each(errors, function(k, v) {
        html = html + '<li>' + v + '</li>';
    });
    html = html + '</ul>';
    html = html + '</div>';

    return html;
}