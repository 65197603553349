$(function(){

    $('.chosen-select').chosen();

    $(document).ajaxComplete(function() {
        $('.chosen-select').chosen();
    });

    moment.locale('nl');

    $('body').on('submit', 'form.js-submit', function(e) {
        e.preventDefault();

        var $form = $(this);

        $.post($(this).attr('action'), $(this).serializeArray())
            .done(function(data) {

                var html = '';

                switch (data.scope) {
                    case 'custom-add-item':
                        html = '<div class="alert alert-success" role="alert">';
                        html = html + data.entity + ' <strong>' + data.title + '</strong> is toegevoegd, <a href="/members/items/edit/' + data.id + '#media" class="alert-link">voeg foto\'s of bestanden</a> toe of ga terug naar <a class="alert-link" href="' + data.link + (data.id > 0 ? '#' + data.id : '') + '">overzicht</a></strong>'
                        html = html + '</div>';
                        $form.html(html);
                        break;

                    case 'custom-add-user-reservation':
                        $form.find('.feedback').html('');
                        $('#user_reservation_table_' + data.custom.day_slot_identifier).append('<tr><td></td><td>' + data.custom.user + '</td><td>' + data.custom.time + '</td></tr>');
                        break;

                    case 'simple':
                        html = '<div class="alert alert-success" role="alert">' + data.message + '</div>';
                        $form.html(html);
                        break;

                    default:
                        html = '<div class="alert alert-' + (data.scope == 'insert' ? 'success' : 'info') + '" role="alert">';
                        html = html + data.entity + ' <strong>' + data.title + '</strong> is ' + (data.scope == 'insert' ? 'toegevoegd' : 'aangepast') + ', terug naar <a class="alert-link" href="' + data.link + (data.id > 0 ? '#' + data.id : '') + '">overzicht</a></strong>'
                        html = html + '</div>';
                        $form.html(html);
                        break;
                }
            })
            .error(function(errors) {

                $form.find('.feedback').html(
                    renderValidationHtml(
                        $.parseJSON(errors.responseText)
                    )
                );

            });
    });

    $('body').on('click', '.table-delete-item', function(e) {
        e.preventDefault();

        var $link = $(this);

        $link.parent().parent().addClass('danger');

        if (confirm('Ben je zeker dat je dit item wilt verwijderen?')) {
            $.get($(this).attr('href'), function() {
                $link.parent().parent().remove();
            });
        } else {
            $link.parent().parent().removeClass('danger');
        }
    });


    $('.slugger').each(function() {
       $(this).stringToSlug({
           setEvents: 'keyup keydown blur',
           getPut: $(this).data('target'),
           space: '-'
       });
    });

    $('#testimonial-read-more').click(function(e) {
        e.preventDefault();
        $(this).parent().parent().parent().hide();
        $(this).parent().parent().parent().next().show();
    });

    $(document).on('click', '.add-plate', function(e) {
        e.preventDefault();

        $('.plate-container').append(
            '<div class="form-group">\n' +
            '    <div class="row plate-row">\n' +
            '        <div class="col-xs-10">\n' +
            '            <input type="text" class="form-control" name="nummerplaten[]" placeholder="1-ABC-123">\n' +
            '        </div>\n' +
            '        <div class="col-xs-2">\n' +
            '            <a href="#" class="btn btn-danger btn-block remove-plate">\n' +
            '                <i class="fa fa-trash" aria-hidden="true"></i>\n' +
            '            </a>\n' +
            '        </div>\n' +
            '    </div>\n' +
            '</div>'
        );
    });

    $(document).on('click', '.remove-plate', function(e) {
        e.preventDefault();

        $(this)
            .parent()
            .parent()
            .parent()
            .slideUp(400, function() {
                $(this).remove();
            })

    });

});